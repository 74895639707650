<template>
  <div class="container">
    <div style="display: flex; justify-content: space-between;">
      <h1
      class="title duration_3 mb-2 animate__animated animate__fadeInUp"
      style="margin-left: -20px"
    >
      CHAT
    </h1>
    <span @click="clear" class="clear">
      Clear
    </span>
    </div>

    <v-progress-linear
      color="cyan"
      v-if="isLoading"
      style="color: #146ab4 !important"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
    <div class="content">
      <div class="taraea" style="margin-bottom: 20px">
        <p class="text-bold"></p>
        <div>
          <pre>
              {{ content }}
            </pre>
        </div>
      </div>

      <div class="input-group">
        <input
          @keyup.enter="onSearch"
          v-model="chat"
          class="form-control"
          type="text"
          required="required"
        />
        <label for="text-1542372332072">Chat here</label>
        <div @click="onSearch" class="req-mark">
          <img style="width:40px;height:40px;cursor: pointer;" src="go.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Lang from '../components/SwitchLangauge.vue'
import axios from "axios";
export default {
  name: "Home",
  components: {
    // Lang,
  },
  data() {
    return {
      isLoading: false,
      chat: "",
      content: "",
      question: {1: ''},
      qIndex: 1
    };
  },
  methods: {
    clear(){
      this.content = "",
      this.chat = ''
    },
    async onSearch() {
      let chatData = ''
      this.question[this.qIndex] = this.chat;
      this.qIndex =+1
      if(this.content !=''){
        chatData = this.content+'\n\n '+this.chat
      } else {
        chatData = this.chat
      }
      var data = {
        message: chatData,
      };
      this.isLoading = true
      var config = {
        method: "post",
        url: "https://us-central1-nearyou-49d62.cloudfunctions.net/ai/chat",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const res = await axios(config);
      this.chat = ""
      if (res.data) {
        if(this.content !=''){
        this.content = this.content + ' ' + res.data.data.choices[0].text;
      } else {
        this.content = res.data.data.choices[0].text;
      }

      }
      this.isLoading = false

    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo({ behavior: "smooth", top: 0 });
  },
};
</script>
<style>
</style>